import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './components/4_views/Login/Login';
import styles from './App.module.scss';
import store from './redux/store';
import { connect } from 'react-redux';
import { includesOneOf } from './helpers/scopecheck';
import Scene from './components/4_views/Scene/Scene';
import RestAPI from './helpers/RestAPI';
import { actionSetLogin } from './redux/actions/actionLogin';

class App extends Component {

  componentDidMount() {
    RestAPI.init().then(() => {
      store.dispatch(actionSetLogin({}));
    });
  }

  render() {
    const { login } = this.props.login;
    const isValidUser =
      login && login.scope && includesOneOf(login && login.scope, ['check']);

    return (
      <div className={styles.host}>
        <BrowserRouter basename={'/' + process.env.REACT_APP_DEPLOY}>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => {
                return !login ? <Login {...props} /> : <Scene />;
              }}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

App = connect(
  (state) => ({
    login: state.login,
  }),
  {} // bind account loading action creator
)(App);

export default App;
