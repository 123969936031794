

const initialState = {
	login: null,
	ipdata: null
};


const reducerLogin = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOGIN':
			return {
				login: action.login
			};
		default:
			return state;
	}
};

export default reducerLogin;
