
export const includesOneOf = (scope, needs) => {
  if(scope && scope.length > 0) {
    for (let i = 0; i < needs.length; i++) {
      if (scope.indexOf(needs[i]) >= 0) {
        return true;
      }
    }
  }
  return false;
};
