import React, { Component } from 'react';
import { Button, Form, Grid, Message, Icon } from 'semantic-ui-react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';

import './Login.css';

import RestAPI from '../../../helpers/RestAPI';
import { includesOneOf } from '../../../helpers/scopecheck';
import store from './../../../redux/store';

const iconError = 'exclamation triangle';
const iconCheck = 'circle notched';
const iconOk = 'check';

const ViewForm = (props) => (
  <div>
    <Form size="large">
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder="Login-Name"
        onChange={(d, e) => props.onChange('user', e.value)}
      />
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Passwort"
        type="password"
        onChange={(d, e) => props.onChange('passwd', e.value)}
      />
      <Button
        color={'blue'}
        disabled={!props.active}
        fluid
        size="large"
        onClick={() => props.onLogin()}
      >
        Login
      </Button>
      <Message
        hidden={props.message === ''}
        icon
        negative={props.icon === iconError}
        positive={props.icon === iconOk}
      >
        <Icon name={props.icon} loading={props.icon === iconCheck} />
        <Message.Content>
          <Message.Header>{props.message}</Message.Header>
        </Message.Content>
      </Message>
    </Form>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      passwd: '',
      message: '',
      showIcon: false,
      showMessage: '',
      buttonactive: true,
    };

    console.log(this.props.history.location);
  }

  onChange(type, data) {
    switch (type) {
      case 'user':
        this.setState({ user: data });
        break;
      case 'passwd':
        this.setState({ passwd: data });
        break;
    }
  }

  onLogin() {
    this.setState({
      showIcon: iconCheck,
      buttonactive: false,
      showMessage: 'Verify',
    });
    RestAPI.login(this.state.user, this.state.passwd)
      .then((res) => {
        console.log(res);
        if (res) {
          if (res.ok) {
          }
          else {
            this.setState({
              showMessage: 'invalid user or password',
              showIcon: iconError,
              buttonactive: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ showProgress: false });
        console.error(err);
        this.setState({
          showMessage: 'Connection Error',
          showIcon: iconError,
          buttonactive: true,
        });
      });
  }

  render() {
    const isChrome = true; // !!window.chrome; // && (!!window.chrome.webstore || !!window.chrome.runtime);

    return (
      <div className="login-form">
        <Grid
          columns={3}
          verticalAlign={'middle'}
          textAlign="center"
          style={{ height: '100%' }}
        >
          <Grid.Column textAlign="center" style={{ width: 400 }}>
            <img
              style={{}}
              src={process.env.PUBLIC_URL + '/assets/vm-logo_128.png'}
              alt="vm"
            />

            <h1>Check</h1>
            <ViewForm
              onChange={(type, data) => {
                this.onChange(type, data);
              }}
              onLogin={() => {
                this.onLogin();
              }}
              message={this.state.showMessage}
              icon={this.state.showIcon}
              active={this.state.buttonactive}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(LoginForm);
