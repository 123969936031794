
//
// Redux init
// 
import { createStore, combineReducers } from 'redux'

import reducerMain from './reducer/reducerMain'
import reducerLogin from './reducer/reducerLogin'

const rootReducer = combineReducers({
  main: reducerMain,
  login: reducerLogin
});
const store = createStore(
	rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;

