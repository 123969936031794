

const initialState = {

	debugMode: false,
	device: {}
};


const reducerMain = (state = initialState, action) => {
	switch (action.type) {

		case 'SET_DEVICE':
			return {
				...state,
				device: action.device
			};
		case 'SET_DEBUG_MODE':
			return {
				...state,
				debugMode: action.debugMode
			};
		default:
			return state;
	}
};

export default reducerMain;
