import store from '../redux/store';
import { actionSetLogin } from '../redux/actions/actionLogin';

// Init: check backend
var API_ROOT = null;
var API_VM = null;
var API_BASE = null;

let server = 'https://vuppetmaster.de';
let local = 'http://localhost';

export const getVM = () => {
  return API_VM;
};

export const getBase = () => {
  return API_BASE;
};

export const init = async () => {
  const hostname = window && window.location && window.location.hostname;
  console.log('init hostname ', hostname);

  if (
    process.env.NODE_ENV === 'production' ||
    hostname.endsWith('vuppetmaster.de')
  ) {
    API_ROOT = server + '/api_vmadmin';
    API_VM = server + '/vm/';
    API_BASE = server;
  } else {
    API_ROOT = local + ':8443/api_vmadmin';
    API_VM = local + ':9443/vm/';
    API_BASE = local;
  }
  console.log('API_ROOT = ', API_ROOT);
  console.log('API_VM = ', API_VM);
};

const post = async (url, data) => {
  console.log('post ', url);

  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response
          .text()
          .then((text) => ({
            ok: false,
            status: response.status,
            error: text,
          }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const postForm = async (url, data) => {
  console.log('postForm ', url);

  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    body: data,
    mode: 'cors',
  }).then((response) => {
    if (!response) return null;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response
        .text()
        .then((text) => ({ ok: false, status: response.status, error: text }));
    }
  });
};

const get = async (url, queryData) => {
  console.log('get ', url);

  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({ ok: false, error: text }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

// AUTHENTICATION

export const login = async (name, passwd) => {
  const result = await post(API_ROOT + '/auth/login', {
    name,
    passwd,
    scope: 'demo',
  });
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const logout = async () => {
  const result = await get(API_ROOT + '/auth/logout');
  if (result && result.ok) {
    store.dispatch(actionSetLogin());
  }
  return result;
};

export const authStatus = async () => {
  const result = await get(API_ROOT + '/auth/status');
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const ping = async (url) => {
  const result = await get(url + '/ping');
  return result;
};

export default {
  post,
  get,

  init,
  login,
  logout,
  authStatus,
  ping,

  getVM,
  getBase,
};
