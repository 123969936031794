import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import Script from 'react-load-script';
import { Icon, Message, Progress, Button, Input } from 'semantic-ui-react';

import styles from './Scene.module.scss';

import { connect } from 'react-redux';

import { getWebglReport } from '../../../helpers/getWebglReport';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Copyright from '../../1_atoms/Copyright/Copyright';
import sleep from '../../../helpers/sleep.js';

const ifvisible = require('ifvisible.js');

const key = '6WAYK-3K1AJ-YJ25F-1A9DH';

const itemInit = 'itemInit';
const itemWebGL = 'itemWebGL';
const itemWebGL2 = 'itemWebGL2';

const items = [
  {
    type: itemInit,
    title: 'Initializing',
  },
  {
    type: itemWebGL,
    title: 'WebGL',
  },
  {
    type: itemWebGL2,
    title: 'WebGL2',
  },
];

const modeLoading = 'modeLoading';

class Scene extends Component {
  constructor(props) {
    super(props);

    const showItems = items.map((item) => {
      return {
        type: item.type,
        title: item.title,
        mode: '',
        state: 'checking',
        data: null,
        text: '',
      };
    });

    this.state = {
      progress: null,
      showItems,
      showAvatar: false,
      vm: false,
      mode: modeLoading,
      showDetails: false,
    };

    ifvisible.on('blur', () => {
      if (this._vuppetmaster) {
        this._vuppetmaster.core.stopAnimation();
      }
    });

    ifvisible.on('focus', () => {});

    this._model = null;
    this._head = null;
  }

  componentDidMount() {
    const report = {
      v1: getWebglReport(1),
      v2: getWebglReport(2),
    };
    this.setState({ report });
  }

  componentWillUnmount() {}

  setItem(type, state, data) {
    let items = this.state.showItems.map((item) => {
      if (item.type === type) {
        item.state = state;
        if (data) item.data = data;
      }
      return item;
    });
    this.setState({ showItems: items });
  }

  handleScriptCreate() {}

  handleScriptError() {
    this.setState({
      scriptError: true,
    });
  }

  async handleScriptLoad() {
    this.setItem(itemInit, 'ok');

    const report = this.state.report;

    this._vuppetmaster = new window.VM.Vuppetmaster({
      assetsQuality: 'optimized',
    });
    document._vm = this._vuppetmaster;
    this._vuppetmaster.on('complete', async () => {
      // ready
      this._model = this._vuppetmaster.models.getFirst();
      this._head = this._model.createCtrlNode('head');

      const render = document.querySelector('#webglcontainer');

      document.addEventListener(
        'mousemove',
        (event) => {
          const x = (event.clientX / document.body.clientWidth - 0.5) * 2;
          const y = (event.clientY / document.body.clientHeight - 0.5) * 2;
          this._head.setEuler((x * Math.PI) / 8, (y * Math.PI) / 8, 0);
        },
        false
      );
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.touches.length == 1) {
            const x =
              (event.touches[0].clientX / document.body.clientWidth - 0.5) * 2;
            const y =
              (event.touches[0].clientY / document.body.clientHeight - 0.5) * 2;
            this._head.setEuler((x * Math.PI) / 8, (y * Math.PI) / 8, 0);
          }
        },
        false
      );
      this.setState({ progress: null, vm: true });

      await sleep(500);

      this.setItem(itemWebGL, report.v1.webglVersion ? 'ok' : 'failed');
      await sleep(2500);
      this.setItem(
        itemWebGL2,
        report.v2 && report.v2.webglVersion ? 'ok' : 'failed'
      );
    });
    this._vuppetmaster.on('progress', ({ value, model_uuid }) => {
      this.setState({ progress: value * 100 });
    });
    this._vuppetmaster.loadProject(null, { app: 'check' });
  }

  render() {
    const { progress, showItems, vm, showDetails, report, text } = this.state;
    const { login } = this.props;

    const backgroundStyle = {
      backgroundImage:
        'url(' + process.env.PUBLIC_URL + '/assets/wire-head.jpg)',
    };

    let vmurl =
      login &&
      (process.env.NODE_ENV === 'production'
        ? 'https://vuppetmaster.de/vmapi/v4/api?key=' + key
        : 'http://localhost:9443/vmapi/v4.local/api?key=' + key);

    const check = (item) => {
      const found = showItems.find((item) => item.type === item.type);
      return found && found.mode === 'visible';
    };

    const messages = (
      <Message size={'mini'} color={'orange'} style={{ height: '100%' }}>
        <div className={styles.fleximage}>
          <div className={styles.fleximageelem}>
            <img
              style={{ width: '200px', paddingRight: '10px' }}
              src={process.env.PUBLIC_URL + '/assets/charamel_logo.png'}
              alt="vm"
            />
          </div>
          <div className={styles.fleximageelem}>
            <img
              style={{ height: '44px' }}
              src={process.env.PUBLIC_URL + '/vm-logo_64.png'}
              alt="vm"
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h2>
            VuppetMaster
            <p /> Browser Compatibility Check
            <p />
          </h2>
        </div>
        <div>
          This tools check if the VuppetMaster render engine runs on your system
        </div>
        {showItems.map((item) => {
          return (
            <Message key={item.type} icon size="tiny">
              <Message.Content>
                <Message.Header>{item.title}</Message.Header>
                {item.text}
              </Message.Content>
              {item.state === 'ok' && (
                <Icon
                  style={{ fontSize: '2em' }}
                  name="check"
                  color="green"
                  size="mini"
                />
              )}
              {item.state === 'checking' && (
                <Icon
                  style={{ fontSize: '2em' }}
                  name="circle notch"
                  size="mini"
                  loading
                />
              )}
              {item.state === 'failed' && (
                <Icon
                  style={{ fontSize: '2em' }}
                  name="delete"
                  color="red"
                  size="mini"
                />
              )}
            </Message>
          );
        })}
        <div className={styles.input}>
          <Input
            className="ControlBar__Input"
            placeholder='text to speak'
            type="text"
            value={text}
            onChange={(event) => {
              this.setState({ text: event.target.value });
            }}
            action
          >
            <input />
            <Button
              className={'ControlBar__Input__Speech'}
              color="orange"
              onClick={() => {
                this._model.speak(text)
              }}
              icon
            >Speak</Button>
          </Input>
        </div>
        <p></p>
        <p></p>
        <Button
          size="mini"
          onClick={() => {
            this.setState({ showDetails: !this.state.showDetails });
          }}
        >
          {showDetails ? 'Hide' : 'Show Details'}
        </Button>
      </Message>
    );

    const hints = <Message>https://get.webgl.org/</Message>;

    return (
      <div
        className={showDetails ? styles.maindetails : styles.main}
        style={backgroundStyle}
      >
        {vmurl && (
          <Script
            url={vmurl}
            onCreate={this.handleScriptCreate.bind(this)}
            onError={this.handleScriptError.bind(this)}
            onLoad={this.handleScriptLoad.bind(this)}
          />
        )}
        {progress && (
          <Progress
            style={{ position: 'absolute', width: '100%', zIndex: '500' }}
            percent={progress}
            color={'orange'}
            size="tiny"
            active
          ></Progress>
        )}
        <div className={styles.flexmain}>
          <div className={styles.flexitem}>
            <div className={styles.message}>{messages}</div>
          </div>
          <div className={styles.flexitem}>
            <div className={styles.render}>
              <div id="webglcontainer" className={styles.renderview}></div>
            </div>
          </div>
        </div>
        <Copyright />
        {showDetails && (
          <Message>
            <h3>Details:</h3>
            <p />
            <CopyToClipboard
              text={JSON.stringify(report, null, 2)}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button size={'mini'} icon>
                Copy
                <Icon name="copy outline" />
              </Button>
            </CopyToClipboard>
            {/*
            <p />
            Or mail it:
            <a href={`mailto:dold@charamel.com?subject=Check&body=${JSON.stringify(report, null, 2)}`}>mail it</a>
*/}
            <div className={styles.details}>
              <pre>{JSON.stringify(report, null, 2)}</pre>
            </div>
          </Message>
        )}
      </div>
    );
  }
}

Scene = connect(
  (state) => ({
    login: state.login.login,
  }),
  {} // bind account loading action creator
)(Scene);

export default Scene;
